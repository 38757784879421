#menu {
  height: 60px;
  max-width: 880px;
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  border-width: 0px;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#imgLogo {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#imagemLogoHeader {
  margin: 0;
  padding: 0;
}

#navMenu1 {
  width: 50%;
  text-decoration: none;
  position: absolute;
  padding: 0;
  margin-right: -150px;
  width: 490px;
  color: #0098da;
  display: flex;
  justify-content: center;
  align-items: center;
}
#navMenu1 ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navMenu1 li,
#navMenu1Alternativo li {
  display: inline;
  text-align: center;
  color: #0098da;
  margin-right: 60px;
  font-weight: 400;
}

#menuLink:hover,
#menuLink:focus {
  text-decoration: none;
  color: #447bbd;
}

#menuAlternativoGrande {
  display: flex;
  justify-content: center;
  align-items: center;
}

#menuAlternativoPequeno {
  display: none;
  justify-content: center;
  align-items: center;
}

#navMenu2 {
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#navMenu2 ul {
  margin: 0;
  padding: 0;
}

#navMenu2 li {
  display: inline;
  text-align: center;
  color: #0098da;
  margin-right: 20px;
}

.imgModalPessoa {
  margin: -50px 0 0 0px;
}
@-moz-document url-prefix() {
  .imgModalPessoa {
    margin: -50px 0 0 0px;
  }
}
.modal-content {
  margin-top: 30px;
  border-radius: 30px;
  max-width: 435px;
  height: 550px;
  background-image: url(../../img/modalFundo.png);
  background-color: transparent;
  background-size: cover;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-body {
  margin-top: 100px;
}
.modal-body h1 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin: -90px 0 30px 160px;
  color: #0098da;
}
.modal-header {
  border: 0;
}
.modal-footer {
  flex-wrap: initial;
  height: 46px;
  background-color: #b29dca;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
}
.botaoModalCadastrar {
  margin-right: -16px !important;
  width: 125px !important;
  height: 45px !important;
  border: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 0 !important;
  background-color: #0098da !important;
}
.loginModal,
.assinarModal {
  margin: 0 10px;
  padding: 0;
  width: 91px !important;
  height: 30px !important;
  background-size: contain;
  border-radius: 10px !important;
  border: 0;
  color: #fff;
  font-family: Barlow Condensed;
  font-weight: 800;
  font-size: 20px;
}

.assinarModal {
  background-color: #b29dca;
}

.loginModal {
  background-color: #0098da !important;
}
button {
  outline: 0;
}
button:focus {
  outline: 0;
}
.loginModal:hover,
.loginModal:active,
.loginModal:focus {
  background-color: #447bbd !important;
  border: 0 !important;
}
.assinarModal:hover,
.assinarModal:active,
.assinarModal:focus {
  background-color: #a582cd !important;
  border: 0 !important;
}
.botaoModalCadastrar:hover,
.botaoModalCadastrar:active,
.botaoModalCadastrar:hover {
  border: 0 !important;
  color: transparent !important;
}
.button:focus {
  outline: none;
  border: 0;
}
.close {
  display: none;
}
.botaoModalCadastrar p {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
  color: #fff;
}
#textoModalSemCadastro {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 18px !important;
  color: white;
  float: right;
  margin-top: 5px;
}
@-moz-document url-prefix() {
  @media (pointer: coarse) {
    .botaoModalCadastrar {
      margin-right: -18px !important;
      width: 130px;
    }
    .botaoModalCadastrar p {
      font-size: 18px !important;
    }
  }
}
@media (max-width: 1920px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
}
@media (max-width: 960px) {
}
@media (max-width: 820px) {
  #navMenu1 li {
    margin-right: 50px;
  }
  #navMenu1 {
    margin-left: 120px;
    margin-right: 0;
  }
  .modal-content {
    margin-left: 50px;
  }
}
@media (max-width: 768px) {
  /*Resolução xs do bootstrap */
  #menu {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  #menuAlternativoGrande {
    display: none;
  }
  #navMenu1 {
    margin-left: 0;
  }

  #menuAlternativoPequeno {
    display: block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #navMenu2 {
    float: left;
    width: 170%;
    padding-left: 40%;
    justify-content: flex-start;
    margin-top: 17px;
  }

  #navMenu1Alternativo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 60px;
  }

  #menu {
    height: 100px;
  }

  #menuContainer {
    justify-content: space-around;
  }
}
@media (max-width: 640px) {
  #imagemLogoHeader {
    margin-left: 15%;
  }
  #navMenu2 {
    padding-left: 30%;
  }
}

@media (max-width: 580px) {
  #navMenu1 li,
  #navMenu1Alternativo li {
    margin-right: 30px;
  }
  #navMenu2 {
    padding-left: 10%;
  }
  #imagemLogoHeader {
    margin-left: 45%;
  }
}
@media (max-width: 550px) {
  .modal-content {
    margin-left: 8%;
  }
}
@media (max-width: 490px) {
  .modal-content {
    margin-left: 2%;
    background-image: none;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .imgModalPessoa {
    display: none;
  }

  .modal-body h1 {
    margin-left: 185px;
  }
  #imagemLogoHeader {
    margin-left: 40%;
  }
  #navMenu2 {
    padding-left: 0;
  }
}
@media (max-width: 457px) {
  /*Resolução xs do bootstrap */
  .modal-content {
    margin-left: 2px;
    margin-right: 5px;
  }

  #navMenu2 li {
    margin-right: 10px;
  }
  #navMenu2 {
    padding-right: 30%;
  }
  #imagemLogoHeader {
    margin-left: 50%;
  }
  #navMenu1Alternativo {
    margin-left: 10px;
  }
}
@media (max-width: 380px) {
  #navMenu1 li,
  #navMenu1Alternativo li {
    margin-right: 15px;
  }
  #navMenu1Alternativo {
    margin-left: 10px;
  }
  #navMenu1Alternativo ul {
    width: 400px;
  }
  #imagemLogoHeader {
    margin-left: 60%;
  }
  #navMenu2 {
    padding-right: 20%;
  }
}
@media (max-width: 335px) {
  #imagemLogoHeader {
    margin-left: 60%;
  }
  #navMenu2 {
    padding-right: 0%;
  }

  #navMenu2 ul {
    width: 200px;
    float: left;
  }
  #navMenu1Alternativo {
    margin-left: 0;
  }

  #navMenu1Alternativo li a {
    font-size: 15px;
  }
}

@media (max-width: 310px) {
  #navMenu2 {
    margin: 15px 0 0 0px;
  }

  #navMenu2 li {
    margin-right: 2px;
  }

  #navMenu1Alternativo li a {
    font-size: 12px;
  }
}

@media (max-width: 296px) {
  #navMenu2 {
    margin: 15px 0 0 0;
  }

  #navMenu2 li {
    margin-right: 0;
  }

  #navMenu1 li,
  #navMenu1Alternativo li {
    margin-right: 10px;
  }

  @media (max-width: 289px) {
    .modal-content {
      display: none;
    }
  }
}
