#accordion h3 {
  color: #b29dca !important;
  font-family: Helvetica, sans-serif;
  font-size: 30px;
  text-indent: 30px;
  text-align: justify;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.resposta {
  height: 0;
  animation: fechar 2s;
  color: #0098da;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  text-indent: 30px;
  text-align: justify;
  margin: 0;
  padding: 0;
}
/* .abrirResposta{
    color:#0098DA;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    text-indent: 30px;
    text-align: justify;
    display: block;
    animation: abrir 2s;
}
@keyframes abrir {
    0%{
        height:0;
    }
    100%{
        height: 360px;
    }
}
@keyframes fechar {
    0%{
        height:360px;
    }
    100%{
        height: 0px;
    }
} */
