#inteiroLogin {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
#img-modal-login {
  margin: -135px 0 130px 0;
}
#modal-login-nucleo h2 {
  color: #b29dca;
  margin-top: -80px;
  margin-bottom: 30px;
  font-family: Barlow Condensed;
  font-weight: 800;
  font-size: 40px;
}
#menuLink {
  float: right;
  margin-right: 16px;
}
.botaoModalCadastrar:hover,
.botaoModalCadastrar:active,
.botaoModalCadastrar:hover {
  border: 0 !important;
  color: transparent !important;
}
.botaoModalCadastrar p {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
  color: #fff;
}
.botaoModalCadastrar {
  margin-right: -16px !important;
  width: 125px !important;
  height: 46px !important;
  border: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 0 !important;
  background-color: #0098da !important;
}
#menuLink:hover,
#menuLink:focus {
  text-decoration: none;
  color: #447bbd;
}

#modal-login-nucleo {
  margin-top: 30px;
  border-radius: 30px;
  width: 435px;
  height: 550px;
  background-image: url(../../img/modalFundo.png);
  background-color: transparent;
  background-size: cover;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
}
.modalForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 334px !important;
}
.modalLabel {
  width: 334px;
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #0098da;
}
.modalImput {
  width: 334px;
  height: 53px !important;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #d2d3d5;

  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #868788;
  padding-left: 10px;
}
.modalImput:focus,
.modalImput:active,
.modalImput:hover {
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #d2d3d5;
}
.modalImput:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #868788;
  -webkit-box-shadow: 0 0 0px 1000px #f6eff6 inset;
}
.modalImput:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #868788;
  -webkit-box-shadow: 0 0 0px 1000px #f6eff6 inset;
}

.modalSubmitButton {
  width: 334px;
  height: 70px;
  background: #b29dca;
  border-radius: 15px;
  border: 0;
  margin-top: 10px;
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.modalSubmitButton:hover,
.modalSubmitButton:focus,
.modalSubmitButton:active {
  background: #b29dca;
}
.modalSubmitButton:active {
  border: 2px ridge #bdb1ca;
}
.modalSubmitButton:disabled {
  background: #e6e7e8 !important;
}
.esqueciSenha {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #0098da;
  margin-left: 200px;
  margin-top: 10px;
}
.loading {
  position: absolute;
  top: 30%;
  left: 45%;
}
.unloading {
  display: none;
}
.MuiCircularProgress-colorPrimary {
  color: #0098da !important;
}
.loadingAtivo {
  display: flex;
}
.circularModal {
  position: absolute;
}

#login-footer {
  flex-wrap: initial;
  height: 46px;
  background-color: #b29dca;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 435px;
  position: relative;
  margin-top: -45px;
  z-index: 100;
}

#textoModalSemCadastro {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 18px !important;
  color: white;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
@media (max-width: 490px) {
  #modal-login-nucleo {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.9);
    width: 355px;
  }

  #login-footer {
    width: 355px;
  }
}

@media (max-width: 490px) {
  #modal-login-nucleo {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.9);
    width: 355px;
  }

  #login-footer {
    width: 355px;
  }
}

@media (max-width: 380px) {
  .modalSubmitButton {
    width: 280px;
  }
  .modalImput {
    width: 280px;
  }
  .modalLabel {
    width: 280px;
  }
  #modal-login-nucleo {
    width: 295px;
  }

  #login-footer {
    width: 295px;
  }
}
@media (max-width: 320px) {
  .modalSubmitButton {
    width: 240px;
  }
  .modalImput {
    width: 240px;
  }
  .modalLabel {
    width: 240px;
  }
  .esqueciSenha {
    margin-left: 130px;
  }
  #modal-login-nucleo {
    width: 255px;
  }

  #login-footer {
    width: 255px;
  }

  .botaoModalCadastrar {
    width: 110px !important;
  }
}
@media (max-width: 289px) {
  .modalSubmitButton {
    width: 240px;
  }
  .modalImput {
    width: 240px;
  }
  .modalLabel {
    width: 240px;
  }
  .esqueciSenha {
    margin-left: 130px;
  }
}
@-moz-document url-prefix() {
  @media (pointer: coarse) {
    .botaoModalCadastrar {
      margin-right: -18px !important;
      width: 130px;
    }
    .botaoModalCadastrar p {
      font-size: 18px !important;
    }
  }
}
