#cookies {
  width: 450px;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  position: fixed;
  bottom: -400px;
  right: 10px;
  z-index: 99;
  color: #0098da;
  font-family: Helvetica, sans-serif;
  text-align: justify;
  text-indent: 20px;
  border: 1px solid #b29dca;
  font-size: 18px;
}
#cookies button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: #0098da;
  color: #fff;
  font-weight: 800;
  border-top: 1px solid #b29dca;
  border-left: 1px solid #b29dca;
  border-bottom: 4px solid #b29dca;
  border-right: 4px solid #b29dca;
}

#cookies div {
  width: 380px;
  margin: 5px 5px;
}

.abrirCookies {
  bottom: 10px !important;
  transition: 1s;
}

.fecharCookies {
  bottom: -400px;
  transition: 1s;
}

.aparecerDiv {
  display: block !important;
}

@keyframes abrir {
  0% {
    height: 0;
  }
  100% {
    height: 340px;
  }
}
@keyframes fechar {
  0% {
    height: 340px;
  }
  100% {
    height: 0px;
  }
}

@media (max-width: 500px) {
  #cookies {
    width: 350px;
  }

  #cookies div {
    width: 300px;
  }
}

@media (max-width: 400px) {
  #cookies {
    width: 250px;
  }

  #cookies div {
    width: 200px;
  }
}
