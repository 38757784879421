.paginacao-inteiro {
  width: 90%;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.paginacao-nucleo {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.paginacao-nucleo > p {
  color: #0098da;
  font-family: Barlow Condensed;
  font-size: 18px;
  font-weight: 600;
}
.valores-paginas {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
}
.valores-paginas > div {
  width: 20px;
  margin: 0 2px;
  padding: 0 7px;
  border-radius: 5px;
  color: #0098da;
  font-family: Barlow Condensed;
  font-size: 18px;
  font-weight: 600;
}
.paginaAtual {
  background-color: #0098da;
  color: #fff !important;
}
