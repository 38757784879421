@import url("https://fonts.googleapis.com/css2?family=Baumans&display=swap");

#conteudoTextoSobreVoce {
  max-width: 880px;
  margin-top: 10px;
}
#conteudoTextoSobreVoce h3 {
  color: #0098da;
  font-family: Helvetica, sans-serif;
  font-size: 30px;
  text-indent: 30px;
  text-align: justify;
}
#conteudoTextoSobreVoce h3 b {
  font-family: "Baumans", cursive;
}
#imagem {
  margin: 0;
  padding: 0;
}
