.menuPainelUsuario {
  height: 60px;
  width: 100%;
  border-bottom: 13.5px solid #91d8f7;
  background-color: #b29dca;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.imagemLogoHeader {
  width: 127px !important;
  height: 37px !important;
  margin-top: 5px;
}
.linkHeaderCliente {
  width: 127px !important;
  height: 37px !important;
  margin-top: 5px;
}
.menuContainerPainelUsuario {
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.menuHeaderPainelUsuario {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 200px;
}
.menuLink {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  list-style-type: none;
  margin-right: 20px;
  margin-top: 10px;
}
.sair {
  margin: 0;
  padding: 0;
  width: 94px !important;
  height: 31px !important;
  background-size: contain;
  border-radius: 10px !important;
  background-color: #0098da !important;
  border: 0;
  color: #fff;
  border-radius: 5px;
  font-family: Barlow Condensed;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  float: right;
}
.sair:disabled {
  background-color: #e6e7e8 !important;
}
.loading {
}

@media (max-width: 880px) {
  .menuHeaderPainelUsuario {
    margin-left: 0px;
    margin-right: -150px;
  }
}
@media (max-width: 760px) {
  .menuHeaderPainelUsuario {
    margin-left: 0px;
    margin-right: -100px;
  }
}
@media (max-width: 600px) {
  .menuHeaderPainelUsuario {
    margin-left: 0px;
    margin-right: -40px;
  }
  .menuHeaderPainelUsuario .menuLink {
    margin-right: 10px;
  }
}
@media (max-width: 460px) {
  .menuHeaderPainelUsuario {
    margin-left: 0px;
    margin-right: -20px;
  }
  .menuHeaderPainelUsuario .menuLink {
    margin-right: 10px;
  }
}
@media (max-width: 396px) {
  .sair {
    width: 30px !important;
  }
}
@media (max-width: 335px) {
  .menuContainerPainelUsuario img {
    margin-left: 20px;
  }
}
@media (max-width: 318px) {
  .sair {
    width: 30px !important;
    margin-left: 10px;
  }
  .menuContainerPainelUsuario img {
    margin-left: 0;
    margin-right: 0;
  }
  .menuHeaderPainelUsuario .menuLink {
    margin-right: 5px;
    margin-top: 5px;
  }
  .menuHeaderPainelUsuario {
    margin-right: 10pxx;
    width: 100px;
  }
}
