.inteiroPaineCliente {
  width: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}
.mainPainelCliente {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 10px;
  margin-right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#paginacao-historico {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pedido-referencia {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.inteiroMainCliente,
.inteiroMainClienteMenu {
  max-width: 940px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.menuCliente {
  width: 150px;
  background: transparent;
  margin-top: 30px;
  margin-right: 0;
  padding: 0;
}
.mainCliente {
  max-width: 800px;
  background: #f6eff6;
  border-radius: 12px;
  z-index: 2;
  margin-bottom: 30px;
}
.dadosCliente {
  margin: 0;
  padding: 0;
}

.botaoMainCliente {
  background-color: #0098da;
  width: 110px;
  height: 34px;
  border-radius: 5px;
  border: 0;
  margin-bottom: 5px;
}
.h1Botao {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.botaoAtivo {
  background-color: #f6eff6;
  width: 150px;
  margin-right: -50px;
  padding-right: 0;
  position: relative;
  z-index: 0;
}

.h1BotaoAtivo {
  color: #0098da;
}
.h1Container {
  background-color: #b29dca;
  width: 183px;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  float: left;
  padding: 5px;
  margin-left: -15px;
  clear: both;
}
.h1Container h1 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.containerInputs {
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
}
.mainClienteImput,
.mainClienteImputEstado,
.mainClienteImputCep {
  width: 350px;
  height: 40px;
  margin-right: 20px;
  border-radius: 7px;
  border: 1px solid #0098da;
  padding: 10px;

  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #454546;
}
.mainClienteImputCep {
  width: 200px !important;
}
.mainClienteImputEstado {
  width: 130px !important;
}
.mainClienteImputBotao {
  width: 125px;
  height: 30px;
  border-radius: 7px;
  border: 0;
  background-color: #b29dca;

  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.botaoConfirmarAlteracoes {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 50px;
}
.mainClienteImputBotaoConfirmarAlteracoes {
  width: 238px;
  height: 46px;
  border-radius: 7px;
  border: 0;
  background-color: #b29dca;

  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.mainClienteImputBotaoConfirmarAlteracoes:focus {
  background-color: #8667a9;
}
.mainClienteImputBotao:focus {
  background-color: #8667a9;
}
.mainClienteImput:focus {
  border-radius: 7px;
  border: 1px solid #0098da;
  background-color: #fff;
  color: #000;
}
.mainClienteLabel p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0098da;

  margin-top: 10px;
  margin-bottom: 3px;
  margin-left: 10px;
}
input[type="checkbox"],
input[type="radio"] {
  --active: #0098da;
  --active-inner: #fff;
  --focus: 2px #0098da;
  --border: #0098da;
  --border-hover: #0098da;
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 10px;
  width: 10px;
  outline: none;
  display: inline-block;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
}
input[type="checkbox"]:after,
input[type="radio"]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition:
    transform var(--d-t, 0.3s) var(--d-t-e, ease),
    opacity var(--d-o, 0.2s);
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.tituloResumoCliente {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #8667a9;
  padding: 0;
  margin-top: -20px;
}
.tituloResumoCliente h2 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #8667a9;
  margin-top: 40px;
  margin-bottom: -15px;
}
.tituloResumoCliente p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #8667a9;
  margin-top: 65px;
  margin-bottom: 5px;
}
.ultimoLivroCliente {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;
  border-bottom: 2px solid #91d8f7;
  padding-bottom: 40px;
}
.ultimoLivroCliente p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0098da;
}
.containerLivroAtual {
  display: flex;
  justify-content: right;
  align-items: center;
}
.livroAtual {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  flex-direction: column;
}
.livroAtual h2 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #0098da;
  float: right;
  border-bottom: 2px solid #b29dca;
  padding-right: 40px;
}
.livroAtual h3 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #0098da;
  float: right;
  padding-right: 40px;
}
.livroAtual h5 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background-color: #b29dca;
  border-radius: 4px;
  padding: 10px;
  padding-right: 30px;
}

.livroAtual p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  color: #0098da;
  padding-right: 40px;
}
.imagemLivro {
  width: 181px;
  height: 156px;
  border: 4px solid #b29dca;
  border-radius: 14px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0;
  margin-left: -30px;
  z-index: 5;
}
.livro {
  max-width: 149px;
  max-height: 128px;
  width: auto;
  height: auto;
}
.visaoGeral {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.visaoGeral h2 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #0098da;
  margin-bottom: -10px;
}
.visaoGeralConteudo {
  max-width: 598px;
  margin: 20px;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #91d8f7;
}
.perfil h2 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0098da;
  border-bottom: 2px solid #0098da;
  width: 40px;
  margin-bottom: 10px;
}
.perfil {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #91d8f7;
  width: 500px;
}
.perfil .dadosPessoais {
  margin: 10px 0;
}
.perfil .dadosPessoais p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #b29dca;
  margin: 2px 0;
  padding: 0;
}
.alterar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.botaoClienteGeralAlterar,
.botaoClienteGeralAlterarEndereço,
.botaoClienteGeralAlterarCartao {
  width: 100px;
  height: 30px;
  border-radius: 7px;
  background-color: #0098da;
}
.plano,
.enderecoEntrega,
.formaDePagamento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 2px solid #91d8f7;
  padding-bottom: 20px;
  width: 500px;
}
.planoAderido h2,
.planoAderido p,
.enderecoEntrega p,
.formaDePagamento p {
  margin: 0;
  padding: 0;
}
.planoAderido h2,
.enderecoEntrega h2,
.formaDePagamento h2 {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0098da;
  border-bottom: 2px solid #0098da;
  width: 100px;
}
.planoAderido p,
.enderecoEntrega p,
.formaDePagamento p {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #b29dca;
}
.imgPlanoAderido {
  width: 150px;
}
.enderecoEntrega h2,
.formaDePagamento h2,
.imputContainer1 {
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .imputContainer2,
  .imputContainer3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .imputContainer1 {
    margin-left: -40px;
  }
  .mainCliente {
    width: 800px;
  }
}
@media (max-width: 950px) {
  .inteiroMainCliente {
    width: 700px;
    margin-left: 50px;
  }
  .mainCliente {
    width: 660px;
  }
  .menuCliente {
    margin-right: -100px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 30px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 600px;
    margin-left: 10px;
  }
  .imputContainer1 {
    margin-left: -25px;
  }
  .imputContainer3 .mainClienteImput {
    width: 250px;
  }
  .mainClienteImput {
    width: 300px;
  }
}
@media (max-width: 850px) {
  .inteiroMainCliente {
    width: 600px;
  }
  .mainClienteImputCep {
    width: 180px !important;
  }
  .mainClienteImputEstado {
    width: 100px !important;
  }
  .mainCliente {
    width: 560px;
  }
  .menuCliente {
    margin-right: -100px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 30px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 550px;
    margin-left: -10px;
  }
  .imputContainer1 {
    margin-left: -25px;
  }
  .mainClienteImput {
    width: 250px;
    margin-left: 0;
  }
  .imputContainer3 .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 400px;
  }
}
@media (max-width: 750px) {
  .inteiroMainCliente {
    width: 500px;
  }
  .mainCliente {
    width: 460px;
  }
  .menuCliente {
    margin-right: -100px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 30px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 550px;
    margin-left: -60px;
  }
  .imputContainer1 {
    margin-left: -25px;
  }
  .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .imputContainer3 .mainClienteImput {
    width: 170px;
    margin-left: 0;
  }

  .mainClienteImputCep {
    width: 130px !important;
  }
  .mainClienteImputEstado {
    width: 80px !important;
  }
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 300px;
  }

  .alterar {
    width: 300px !important;
  }
}
@media (max-width: 650px) {
  .inteiroMainCliente {
    width: 400px;
  }
  .mainCliente {
    width: 360px;
  }
  .menuCliente {
    margin-right: -100px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 30px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 350px;
    margin-left: -20px;
    flex-direction: column;
  }
  .imputContainer1 {
    margin-left: -20px;
    display: flex;
    flex-direction: column;
  }

  .mainClienteImput {
    width: 300px;
    margin-left: 0;
  }
  .imputContainer3 .mainClienteImput {
    width: 300px;
    margin-left: 0;
  }
  .mainClienteImputCep {
    width: 300px !important;
  }
  .mainClienteImputEstado {
    width: 300px !important;
  }
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 200px;
    flex-direction: column;
  }
  .perfil img,
  .plano img,
  .enderecoEntrega img,
  .formaDePagamento img {
    margin-top: 20px;
  }
  .perfil button {
    margin-bottom: 20px;
  }
}
@media (max-width: 550px) {
  .alterar {
    width: 200px !important;
  }

  .alterar h2 {
    width: 190px !important;
    margin-left: 0 !important;
    border: 0 !important;
  }

  .formaDePagamento h2 {
    font-size: 12px !important;
  }

  .inteiroMainCliente {
    width: 300px;
  }
  .mainCliente {
    width: 260px;
  }
  .menuCliente {
    margin-right: -100px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 30px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 250px;
    margin-left: -20px;
    flex-direction: column;
  }
  .imputContainer1 {
    margin-left: -20px;
    display: flex;
    flex-direction: column;
  }

  .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .imputContainer3 .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .mainClienteImputCep {
    width: 200px !important;
  }
  .mainClienteImputEstado {
    width: 200px !important;
  }
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 180px;
    margin-left: -22px;
  }
  .perfil h2,
  .perfil .dadosPessoais p,
  .plano h2,
  .plano p,
  .enderecoEntrega h2,
  .enderecoEntrega p,
  .formaDePagamento h2,
  .formaDePagamento p {
    margin-left: -20px;
  }
  .tituloResumoCliente {
    height: 170px;
    align-items: flex-start;
  }
  .containerLivroAtual {
    flex-direction: column-reverse;
  }
  .imagemLivro {
    float: none;
    margin-right: -30px;
  }
  .livroAtual h2 {
    width: 200px;
    margin-right: -30px;
    float: none;
    padding: 0;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 160px;
    margin-left: -32px;
  }
  .perfil p:last-child {
    margin-left: -26px;
    overflow: hidden;
    width: 160px;
  }
  .perfil .alterar,
  .planoAderido img,
  .enderecoEntrega .alterar,
  .formaDePagamento .alterar {
    margin-left: -10px;
  }
  .livroAtual h2 {
    width: 200px;
    margin-right: -50px;
  }
  .livroAtual a {
    width: 150px;
    margin-right: -30px;
  }
  .inteiroMainCliente {
    width: 250px;
  }
  .mainCliente {
    width: 220px;
  }
  .menuCliente {
    margin-right: -60px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 10px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 200px;
    margin-left: -10px;
    flex-direction: column;
  }
  .imputContainer1 {
    margin-left: -35px;
    display: flex;
    flex-direction: column;
  }

  .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .imputContainer3 .mainClienteImput {
    width: 200px;
    margin-left: 0;
  }
  .mainClienteImputCep {
    width: 200px !important;
  }
  .mainClienteImputEstado {
    width: 200px !important;
  }
}
@media (max-width: 350px) {
  .perfil h2,
  .plano h2,
  .enderecoEntrega h2,
  .formaDePagamento h2 {
    font-size: 16px;
    border: 0;
    text-decoration: underline;
  }
  .perfil img,
  .enderecoEntrega img,
  .formaDePagamento img {
    margin-left: -25px;
  }
  .plano img {
    margin-left: -20px;
  }
  .perfil,
  .plano,
  .enderecoEntrega,
  .formaDePagamento {
    width: 124px;
  }
  .perfil p:last-child {
    width: 118px;
  }
  .perfil .alterar,
  .planoAderido img,
  .enderecoEntrega .alterar,
  .formaDePagamento .alterar {
    margin-left: -15px;
  }
  .livroAtual h2 {
    width: 165px;
  }
  .livroAtual a {
    margin-right: -45px;
  }
  .imagemLivro {
    width: 161px;
  }
  .livroAtual h3 {
    width: 160px;
    margin-right: -95px;
  }
  .tituloResumoCliente {
    flex-direction: column;
    height: 120px;
  }
  .tituloResumoCliente h2 {
    font-size: 16px;
  }
  .tituloResumoCliente p {
    margin-top: 10px;
    font-size: 16px;
  }
  .inteiroMainCliente {
    width: 210px;
  }
  .mainCliente {
    width: 185px;
  }
  .menuCliente {
    margin-right: -60px;
    margin-left: 0;
  }
  .inteiroMainClienteMenu {
    margin-left: 10px;
  }
  .imputContainer2,
  .imputContainer3 {
    width: 200px;
    margin-left: -28px;
    flex-direction: column;
  }
  .imputContainer1 {
    margin-left: -39px;
    display: flex;
    flex-direction: column;
  }
  .botaoMainCliente {
    width: 100px;
  }

  .mainClienteImput {
    width: 175px;
    margin-left: 0;
  }
  .imputCheckbox .mainClienteLabel p {
    font-size: 16px;
  }
  .imputContainer3 .mainClienteImput {
    width: 175px;
    margin-left: 0;
  }
  .mainClienteImputCep {
    width: 175px !important;
  }
  .mainClienteImputEstado {
    width: 175px !important;
  }
  .botaoAtivo {
    width: 120px !important;
  }
}
